import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SpinnerLoading from "../../components/common/spinnerLoading"

class Articolo extends Component {
  state = {
    slug: "",
    product: {},
    loading: true,
  }

  componentDidMount = async () => {
    const { product } = this.props.pageContext
    this.setState({ product, loading: false })
  }

  render() {
    const { loading, product } = this.state
    return (
      <Layout>
        <SEO title="News" keywords={[`vision care`]} description="News" />

        <div className="container">
          <div className={"row"}>
            <div className={"col-12"}>
              {loading && (
                <div className="my-5 py-5">
                  <SpinnerLoading />
                </div>
              )}

              {!loading && (
                <div>
                  <h1 className={"page-title"}>{product.attributes.title}</h1>

                  <div className={"row"}>
                    <div className="col-12 col-lg-6">
                      <img
                        className={"product-image"}
                        src={product.relationships.media.data[0].attributes.url}
                        alt={product.attributes.title}
                      />
                    </div>

                    <div className="col-12 col-lg-6">
                      <div
                        className={"product-body"}
                        dangerouslySetInnerHTML={{
                          __html: product.attributes.body,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Articolo
